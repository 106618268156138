import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helper/TextSerializer";
import Fader from "../helper/Fader";
import SEO from "../components/seo";

export const query = graphql`
  query NewsTemplateQuery($id: String!) {
    page: allSanityPage(filter: { slug: { current: { eq: "news" } } }) {
      nodes {
        backgroundImage {
          asset {
            fixed(width: 1600, height: 900) {
              ...GatsbySanityImageFixed_withWebp_noBase64
            }
          }
        }
      }
    }
    news: sanityNews(id: { eq: $id }) {
      id
      authors {
        fullName
      }
      _rawBody(resolveReferences: { maxDepth: 8 })
      publishedAt(formatString: "MMMM Do YYYY")
      pageSEO {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
      title
    }
  }
`;

const NewsTemplate = ({ data }) => {
  const { news, page } = data;

  return (
    <Layout
      className="page-article-container"
      backgroundImage={page.nodes[0].backgroundImage?.asset?.fixed}
    >
      <SEO
        title={(news.pageSEO && news.pageSEO.metaTitle) || news.title}
        description={news.pageSEO && news.pageSEO.metaDescription}
        image={news.pageSEO && news.pageSEO.metaImage}
      />
      <Fader>
        <section className="page-hero">
          <Link className="news-index-link" to="/news">
            Back to news
          </Link>
          <h1 className="page-heading h0">{news.title}</h1>
        </section>
      </Fader>
      <Fader delay={1}>
        <section className="article-body page-body">
          <div className="page-body-inner">
            <div className="article-content">
              <BlockContent
                blocks={news._rawBody}
                serializers={{
                  types: {
                    block: serializers.types.block,
                    undefined: serializers.undefined,
                    image: serializers.image,
                    youtube: serializers.types.youtube,
                  },
                  marks: serializers.marks,
                }}
              />
            </div>
            <aside className="article-details">
              <ul className="article-details-list">
                {news.authors[0] &&
                  news.authors.map((author, i) => {
                    return <li key={i}>{author?.fullName}</li>;
                  })}
                <li>{news.publishedAt}</li>
              </ul>
            </aside>
          </div>
        </section>
      </Fader>
    </Layout>
  );
};

export default NewsTemplate;
